import React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import opsio from "../images/logo_opsio.svg";
import devopsImage from "../images/devopsImage.jpg";
import "react-phone-input-2/lib/style.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "../styles/contact.scss";
import aws from "../images/aws-logo.svg";
import microsoft from "../images/micro.svg";
import google from "../images/google.svg";
import "../styles/about.scss";
import "../styles/home.scss";
import branas from "../images/branas.svg";
import forlaget from "../images/forlaget.svg";
import politico from "../images/politico.svg";
import lofbergs from "../images/lofbergs.svg";
import Eow from "../images/eraofwe.svg";
import opus from "../images/opus.png";
import savr from "../images/savr.png";
import silverrail from "../images/silverrail.webp";
import integrate from "../images/Integrated.svg";
import competency from "../images/Competency.svg";
import service from "../images/as_a_service.svg";
import Footer from "../components/footer";
import { Contact } from "../components/campaign/Contact";
import vmware from "../images/vmware.svg";
import OpsioAWSPartnership from "../components/campaign/OpsioAWSPartnership";
import { Helmet } from "react-helmet";

const Campaign = ({ data }) => {
  const {
    HeroSection: { cta, des, heading1, heading2, title },
    Consulting: {
      title: consultingTitle,
      heading,
      des: consultingDes,
      bulletPointsWithDesc,
      placeholders,
      checkbox1,
      checkbox2,
    },
    AboutUs: {
      title: aboutTitle,
      des1,
      des2,
      imageHeading,
      rightSectionHeading,
      rightSection,
    },
    testimonial: { title: testimonialTitle, testimonials },
    clients: { title: clientTitle },
    partnership,
    metaDes,
    metaTitle,
    slug,
  } = data.allStrapiCampaign.edges[0].node;

  const getFooter = data.allStrapiLayout.edges[0].node;

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcbmXUdAAAAAM8BACZCq5rzXPHfn1BpVeNv5f5o">
      <SEO
        noindexForce={false}
        description={metaDes}
        slug={slug}
        title={metaTitle}
      />
      <div className="service_upper_class_campaign">
        <div
          className="serviceHeroSectionCampaign d-md-flex flex-md-column  px-md-5 px-4 pb-0 "
          id="heading"
        >
          <div className="mb-md-5 pb-md-5 mb-5">
            <img src={opsio} alt="logo" />
          </div>
          <div className="d-md-flex justify-content-md-between mb-5 pb-5">
            <div>
              <h4 className="serviceHeroSectionTitle text-uppercase text-md-start text-center m-0 mb-3 open-sans">
                {title}
              </h4>
              <h1 className="serviceHeroSectionHeading text-md-start text-center mx-0 mb-3">
                {heading1}
                <span style={{ color: "#58C9E8" }} className="m-0">
                  {heading2}
                </span>
              </h1>
              <div
                className="serviceHeroSectionDescription text-md-start text-center  me-0 ms-0 my-4"
                dangerouslySetInnerHTML={{ __html: des }}
              />

              <div className="opsBtnwrap d-flex justify-content-center justify-content-md-start">
                <Link className="opsBtnlink " to="#contact-us">
                  <p>{cta}</p>
                </Link>
              </div>
            </div>
            <div>
              <img
                src={devopsImage}
                className="rounded imageSize"
                alt="devops"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ourApproachWhiteOfferings serviceHotspot">
        <div className="containerwrap">
          <div className="approchTitle ">{consultingTitle}</div>
          <div className="approchHeading mb-0 roboto">{heading}</div>
          <div
            className="approchdescription mt-5"
            dangerouslySetInnerHTML={{ __html: consultingDes }}
          />
        </div>
      </div>
      <div className="processOpsioOfferings serviceHotspot py-5 ">
        <div className="mx-md-5 mx-3">
          <div className="prorcss_wrap w-100" id="contact-us">
            <div className="d-md-flex justify-content-md-between">
              <div className="processRightSectionServices w-100">
                <div className="inner_process d-md-flex justify-content-md-center w-100">
                  <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                    {bulletPointsWithDesc &&
                      bulletPointsWithDesc.map((bulletPoint, index) => (
                        <div
                          className="processDetailsDesCampaign col-md-12 col-12"
                          key={index}
                        >
                          <div className="number">
                            <>{index < 9 ? `0${index + 1}` : `${index + 1}`}</>
                          </div>
                          <div className="process_content_title mb-3">
                            {bulletPoint.title}
                          </div>
                          <div className="process_content_solution">
                            {bulletPoint.des}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <Contact
                placeholders={placeholders}
                checkbox1={checkbox1}
                checkbox2={checkbox2}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="aboutUs py-5">
        <div className="containerwrap-about d-md-flex align-items-start pt-5">
          <div className="aboutUsLeftSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle open-sans-serif">{aboutTitle}</div>
            <div className="aboutUsDescription" style={{ color: "white" }}>
              {des1}
            </div>
            <div className="aboutUsDescription mt-3" style={{ color: "white" }}>
              {des2}
            </div>
            <div className="aboutUslogoSection">
              <div className="aboutUslogoSectionTitle">
                <div className="title">{imageHeading}</div>
                <div className="brands">
                  <figure>
                    <img
                      className="pt-2"
                      width="50"
                      src={aws}
                      alt="Amazon Web Services"
                    />
                  </figure>
                  <figure>
                    <img width="200" src={microsoft} alt="Azure" />
                  </figure>
                  <figure>
                    <img width="120" src={google} alt="Google Cloud" />
                  </figure>
                  <figure>
                    <img width="120" src={vmware} alt="VM Ware" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUsRightSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle text-center open-sans-serif">
              {rightSectionHeading}
            </div>
            <div className=" d-md-flex justify-content-md-center d-flex flex-column flex-md-row gap-5 flex-wrap">
              {rightSection.map(({ title, des }, index) => (
                <div className="card   px-4 align-items-md-center  ">
                  <div className="mb-2">
                    {index === 0 && (
                      <img
                        src={integrate}
                        alt={title}
                        width="70px"
                        height="60px"
                      />
                    )}
                    {index === 1 && (
                      <img
                        src={competency}
                        alt={title}
                        width="80px"
                        height="60px"
                      />
                    )}
                    {index === 2 && (
                      <img
                        src={service}
                        alt={title}
                        width="60px"
                        height="60px"
                      />
                    )}
                  </div>
                  <div className="d-md-flex flex-md-column text-md-start text-center ms-md-4">
                    <div className="card-title">{title}</div>
                    <div className="card-des ">{des}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="successStories pt-0">
        <div className="successSwapCampaign">
          <div className="successStoriesHeading mt-0 pt-0">{clientTitle}</div>

          <div className="successStoriesImages">
            <div className="inside_stories flex-wrap mt-md-4">
              <div className="d-md-flex">
                <div className="success_img ">
                  <a href="https://www.savr.com/" target="_blank">
                    <img
                      className="successStoriesImgCampaign"
                      id="savr"
                      alt="SAVR"
                      src={savr}
                    />
                  </a>
                </div>
                <div className="success_img ">
                  <a href="https://www.silverrailtech.com/" target="_blank">
                    <img
                      className="successStoriesImgCampaign"
                      id="silverrail"
                      alt="SilverRail"
                      src={silverrail}
                    />
                  </a>
                </div>
              </div>
              <div className="d-md-flex align-items-md-center mt-md-5 mt-3">
                <div className="success_img">
                  <a
                    href="https://www.branas.se/om-branasgruppen/"
                    target="_blank"
                  >
                    <img
                      className="successStoriesImg"
                      alt="branas"
                      src={branas}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="http://www.okforlaget.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="forlaget"
                      src={forlaget}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://en.lofbergs.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="politico"
                      src={politico}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://www.politico.com/" target="_blank">
                    <img
                      className="successStoriesImg"
                      alt="lofbergs"
                      src={lofbergs}
                    />
                  </a>
                </div>
                <div className="success_img">
                  <a href="https://www.eraofwe.com/" target="_blank">
                    <img
                      className="successStoriesImg"
                      id="eraofwe"
                      alt="lofbergs"
                      src={Eow}
                    />
                  </a>
                </div>
                <br />
                <br />
                <div className="success_img">
                  <a href="https://www.opus.se/" target="_blank">
                    <img
                      className="successStoriesImg"
                      id="opus"
                      alt="Opus"
                      src={opus}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpsioAWSPartnership data={partnership} />
      <Footer
        data={getFooter.navbar.footer}
        requirements={getFooter.navbar.requirements}
        locale={getFooter.locale}
        hideCta={false}
        campaignLink={"#contact-us"}
      />
    </GoogleReCaptchaProvider>
  );
};

export default Campaign;

export const query = graphql`
  query CampaignPage {
    allStrapiCampaign {
      edges {
        node {
          AboutUs {
            des1
            des2
            imageHeading
            rightSection {
              des
              title
            }
            rightSectionHeading
            title
          }
          Consulting {
            checkbox1
            checkbox2
            des
            heading
            title
            bulletPointsWithDesc {
              des
              id
              title
            }
            placeholders {
              email
              message
              name
              organization
              file
              btn
            }
          }
          clients {
            title
          }
          testimonial {
            title
            testimonials {
              des
              name
            }
          }
          partnership {
            mainTitle
            intro
            heading
            advantages {
              title
              des
            }
            des1
            des2
          }
          metaDes
          metaTitle
          slug
          HeroSection {
            cta
            des
            heading1
            heading2
            title
          }
        }
      }
    }
    allStrapiLayout {
      edges {
        node {
          locale
          navbar {
            requirements {
              btn
              email
              des
              title
            }
            footer {
              heading
              changePrivacy
              copyright
              support {
                createTicket
                contactSupport
              }
              contact {
                email
                phone
                address1
                address2
              }
              values {
                COMPANY {
                  name
                  src
                }
                INDUSTRIES {
                  name
                  src
                }
                PLATFORMS {
                  name
                  src
                }
                RESOURCES {
                  name
                  src
                }
                SERVICES {
                  name
                  src
                }
                SERVICESAWS {
                  name
                  src
                }
                SERVICESAZURE {
                  name
                  src
                }
                SERVICESGCP {
                  name
                  src
                }
                TECHNOLOGIES {
                  name
                  src
                }
                TECHNOLOGIES2 {
                  name
                  src
                }
                CLOUDPLATFORMS {
                  name
                  src
                }
                CLOUDSOLUTIONS {
                  name
                  src
                }
                DATAAI {
                  name
                  src
                }
                SECURITYCOMPLIANCE {
                  name
                  src
                }
                CODECRAFTING {
                  name
                  src
                }
                WORK {
                  name
                  src
                }
                CONTACTUS {
                  name
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
